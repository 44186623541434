<template>
  <div>
    <img
      src="@/assets/images/claro-parcerias.png"
      alt="Logo Clube Conecta"
      class="w-[218px] h-[30px]"
    />
  </div>
</template>

<script setup lang="ts"></script>
